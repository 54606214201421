import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';

export default class HomeRedirect extends Component<RouteComponentProps<any>, any> {

render()
    {
        let origin = window.location.origin || 'https://expertsmarketresearch.com';
        if(origin.includes("hub.")) {
            origin = origin.replace('hub.', 'www.')
            window.location.replace(origin);
        } else if(origin.includes('expertsmarketresearch.com')){
            window.location.replace('https://expmktrsch.wpengine.com/');
        }
        return <div></div>
    }    
}
