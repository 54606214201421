import React, {Component} from 'react';
import {Button, Row, Col, Form, Input, Spin, Typography} from 'antd';
import {verifySurveyExpert} from '../utils/apis/ExpertSurveyApi';
import {Redirect} from "react-router-dom";
import LinkToPopupRenderer from "./LinkToPopupRenderer";
import ReactMarkdown from "react-markdown";

interface LinkedinVerificationProps {
  refId: string,
  surveyRegistrationId?: number,
  requireManualVerification?: boolean,
  email?: string
}

interface LinkedinVerificationStates {
  loading: boolean,
  redirectToSurvey: boolean,
  redirectToError: boolean,
  guid?: string,
  redirectToThanksForApplying: boolean
}

const { Title, Paragraph } = Typography;

export default class LinkedinVerification extends Component<LinkedinVerificationProps, LinkedinVerificationStates> {

  state: LinkedinVerificationStates = {
    loading: false,
    redirectToError: false,
    redirectToSurvey: false,
    guid: "",
    redirectToThanksForApplying: false
  }

  renderRedirectToError = () => {
    if (this.state.redirectToError) {
      return <Redirect to='/error'/>
    }
  }

  renderRedirectToSurvey = () => {
    if (this.state.redirectToSurvey) {
      return <Redirect to={`/${this.state.guid}`}/>
    }
  }

  renderRedirectToThanksForApplying = () => {
    if (this.state.redirectToThanksForApplying) {
      return <Redirect to='/thanksforapplying' />
    }
  }

  submitProfileUrl = async (values) => {
    try {
      this.setState({loading: true});
      let profile = this.buildProfileUrl(values.profile);
      const res = await verifySurveyExpert(profile, this.props.surveyRegistrationId, this.props.refId, values.email);
      this.setState({
        loading: false,
        guid: res.guid,
        redirectToSurvey: !res.requireManualVerification,
        redirectToThanksForApplying: !!res.requireManualVerification
      });
    } catch (error) {
      this.setState({loading: false, redirectToError: true});
    }
  }

  buildProfileUrl(profile: string) {
    let regex = /https:\/\//;
    if (!regex.test(profile)) {
      return `https://${profile}`;
    }
    return profile;
  }

  render() {
    const subtext = "Last step to check if you're a fit, we just need your public Linkedin URL. If you don't know your Linkedin URL, [click here](https://www.linkedin.com/in/me/detail/contact-info/) to see your URL (and copy it below).";
    const layout = {
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
    };

    return (
      <div>
        {this.renderRedirectToError()}
        {this.renderRedirectToSurvey()}
        {this.renderRedirectToThanksForApplying()}
        <Row gutter={[0, 16]} style={{ marginTop: 32 }}>
          <Col span={18} offset={6}><Title level={3} style={{ fontSize: 32 }} >Thanks for logging in with Linkedin</Title></Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col span={14} offset={6}>
            <ReactMarkdown source={subtext} escapeHtml={true}
                           renderers={{link: (props) => LinkToPopupRenderer(props, 500, 300)}} />
          </Col>
        </Row>
        <Row gutter={[0, 16]}>
          <Col span={10} offset={6}>
            {
              this.state.loading && <Spin/>
            }
            {
              !this.state.loading && <Form {...layout} onFinish={this.submitProfileUrl} fields={[
                {
                  name: ['email'],
                  value: this.props.email
                }
              ]}>
                  <Form.Item label="Your LinkedIn Public URL" name="profile"
                             rules={[{required: true, message: 'Please input your valid linkedin Url.' },
                             { type: 'url',
                               transform: (value) => {
                                  return this.buildProfileUrl(value);
                               },
                               validator: (rule, value) => {
                                  if (value.includes("/in/") && value.includes("linkedin") && !(value.includes("linkedin.com/in/me/")
                                   )) {
                                    return Promise.resolve();
                                  } else {
                                    return Promise.reject("It appears you have not inputted your LinkedIn Profile URL, please click on the “click here” button above, wait for the page to load, then copy the URL from there")
                                  }
                               } }]}>
                      <Input/>
                  </Form.Item>
                  <Form.Item
                      name="email"
                      label="Email"
                      rules={[
                        {
                          required: true,
                          message: 'Please input your email',
                        }, { type: 'email' }
                      ]}
                  >
                      <Input />
                  </Form.Item>
                  <Form.Item>
                      <Button type="primary" htmlType="submit" size="large">
                          Submit
                      </Button>
                  </Form.Item>
              </Form>
            }
          </Col>
        </Row>
      </div>
    )
  }
}

