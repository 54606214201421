import React, { Component } from 'react';
import {Button, Row, Col, Alert, Form, Input, Checkbox, Spin} from 'antd';
import { Redirect } from 'react-router-dom';
import MessageBody from "./MessageBody";
import {getSurveyExpertRybbonPayment, sendSurveyExpertRybbonPayment} from "../utils/apis/ExpertSurveyApi";
import {SurveyRybbonPaymentResponseInfo} from "../utils/models/SurveyRybbonPaymentResponseInfo";
import {SurveyExpertInfo} from "../utils/models/SurveyExpertInfo";
import PaymentCompleted from "./PaymentCompleted";
import PaymentDeferred from "./PaymentDeferred";

interface PaymentRouteProps {
    guid: string,
    redirectLink: string,
    header?: string,
    subtext: string,
    termsHeader?: string,
    curQueries: URLSearchParams
}

interface PaymentStates {
    redirectToError: boolean,
    redirectToDeferred: boolean,
    redirectToCompleted: boolean,
    showPaymentSubmitMessage: boolean,
    showPaymentErrorMessage: boolean,
    paymentSubmitMessage?: string,
    paymentErrorMessage?: string,
    optIn: boolean,
    loading: boolean
}

const PAYMENT_EMAIL_ENTRY_HANDLE_BAR = '[paymentEmailEntry]';

export default class Payment extends Component<PaymentRouteProps, PaymentStates> {
    
    state: PaymentStates = {
        redirectToError: false,
        redirectToDeferred: false,
        redirectToCompleted: false,
        showPaymentSubmitMessage: false,
        showPaymentErrorMessage: false,
        optIn: false,
        loading: false
    }

    async componentDidMount() {
        try {
            const response: SurveyRybbonPaymentResponseInfo = await getSurveyExpertRybbonPayment(this.props.guid);
            this.setState({ optIn: response.optIn })
            if (response.deferred) {
                this.setState({ redirectToDeferred: true });
            } else if (response.rybbonGenerated) {
                this.setState({ redirectToCompleted: true });
            }
        } catch (error) {
            this.setState({ redirectToError: true });
        }
    }

    renderRedirectToError = () => {
        if (this.state.redirectToError) {
            return <Redirect to='/error' />
        }
    }

    submitPayment = async (values: any) => {
        const expertInfo: SurveyExpertInfo = {
            email: values.email,
            phone: values.phone,
            optIn: values.optIn
        }
        try {
            this.setState({ loading: true })
            const response: SurveyRybbonPaymentResponseInfo = await sendSurveyExpertRybbonPayment(this.props.guid, expertInfo);
            let paymentSubmitMessage;
            if (response.deferred) {
                paymentSubmitMessage = `We need to do some additional verification checks before payment will be sent. You should 
    receive an email in 1-2 weeks.

If there are any problems, please email us! We’re here to help at payments@newtonx.com.`
            } else {
                paymentSubmitMessage = `We are sending you an email now, it should arrive within the next 60 seconds. Look for an email from payments@newtonx.com, it may have landed in your spam folder. Move the email to your inbox to enable the link and collect your payment.`
            }
            this.setState({ showPaymentSubmitMessage: true, loading: false, paymentSubmitMessage: paymentSubmitMessage });
        } catch (error) {
            const paymentErrorMessage = `Looks like we are experiencing a technical problem. Can you try again in 30 seconds, please? If it does not work, can you please email us a copy of the link in your browser to “payments@newtonx.com” and we will look into this for you. Sorry for the inconvenience.`
            this.setState({ showPaymentErrorMessage: true, loading: false, paymentErrorMessage: paymentErrorMessage });
        }
    }

    render() {
        const [textBeforeInput, textAfterInput] = this.props.subtext.split(PAYMENT_EMAIL_ENTRY_HANDLE_BAR, 2);
        const showPaymentForm = !this.state.redirectToDeferred && !this.state.redirectToCompleted;

        const showPaymentCompleted = this.state.redirectToCompleted && <PaymentCompleted />;
        const showPaymentDeffered = this.state.redirectToDeferred && <PaymentDeferred />;
        const formItemLayout = {
            labelCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 8,
                },
            },
            wrapperCol: {
                xs: {
                    span: 24,
                },
                sm: {
                    span: 16,
                },
            },
        };
        return (
            <div>
                {this.renderRedirectToError()}
                {showPaymentCompleted}
                {showPaymentDeffered}
                {
                    showPaymentForm && <div>
                        <MessageBody subtext={textBeforeInput} header={this.props.header} />
                        <Row gutter={[0, 16]}>
                            {
                                this.state.showPaymentSubmitMessage &&
                                <Col span={10} offset={6}>
                                    <Alert
                                  message={this.state.paymentSubmitMessage}
                                  type="success" showIcon />
                                </Col>
                            }
                            {
                                this.state.showPaymentErrorMessage &&
                                <Col span={10} offset={6}>
                                    <Alert
                                  message={this.state.paymentErrorMessage}
                                  type="error" showIcon />
                                </Col>
                            }
                            {
                                this.state.loading &&  <Col span={10} offset={6}><Spin /></Col>
                            }
                            {
                                !this.state.showPaymentSubmitMessage && !this.state.showPaymentErrorMessage && !this.state.loading &&
                                <Col span={6} offset={6}><Form  {...formItemLayout} onFinish={this.submitPayment} fields={[
                                    {
                                        name: ['optIn'],
                                        value: this.state.optIn
                                    }
                                ]} >
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email!',
                                            }, { type: 'email' }
                                        ]}
                                        hasFeedback
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        name="confirm Email"
                                        label="Confirm Email"
                                        dependencies={['email']}
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please confirm your email!',
                                            },
                                            { type: 'email' },
                                            ({ getFieldValue }) => ({
                                                validator(rule, value) {
                                                    if (!value || getFieldValue('email') === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject('The two emails that you entered do not match!');
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Phone" name="phone">
                                        <Input />
                                    </Form.Item>
                                    <Form.Item name="optIn" valuePropName="checked">
                                        <Checkbox>Opt in for future opportunities</Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button size="large" type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form></Col>
                            }
                        </Row>
                        <MessageBody subtext={textAfterInput} />
                    </div>
                }
            </div>
        )
        
    }
}
