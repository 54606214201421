import React, {Component} from "react";
import {RouteComponentProps} from "react-router";
import {getOrCreateSurveyGuidForPanelExpert} from "../utils/apis/ExpertSurveyApi";
import {SurveyRequestExpert} from "../utils/models/SurveyRequestExpert";
import {Redirect} from "react-router-dom";

interface PanelRouteProps {
  panelId: string,
  panelExpertId: string
}

interface PanelStates {
  redirectToError: boolean,
  guid?: string
}

export default class Panel extends Component<RouteComponentProps<PanelRouteProps>, PanelStates> {

  state: PanelStates = {
    redirectToError: false
  }

  async componentDidMount() {
    const {
      panelId,
      panelExpertId
    } = this.props.match.params;

    try {
      const panelExpert: SurveyRequestExpert = await getOrCreateSurveyGuidForPanelExpert(panelId, panelExpertId);
      this.setState({
        guid: panelExpert.guid
      });
    } catch (error) {
      this.setState({ redirectToError: true });
    }
  }

  render() {
    return (
      <div>
        {
          this.state.redirectToError && <Redirect to='/error' />
        }
        {
          this.state.guid && <Redirect to={`/${this.state.guid}`} />
        }
      </div>
    );
  }

}