import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.less';
import SurveyRedirect from './components/Redirect';
import HomeRedirect from './components/HomeRedirect';
import Error from './components/Error';
import TermsAndConditions from "./components/TermsAndConditions";
import Panel from "./components/Panel";
import Refer from "./components/Refer";
import NoInterest from "./components/NoInterest";
import LongRefer from './components/LongRefer';
import ThanksForApplying from "./components/ThanksForApplying";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path='/terms' component={TermsAndConditions} />
        <Route path='/error' component={Error} />
        <Route path='/thanks-notamatch' component={NoInterest} />
        <Route path='/refer' component={Refer} />
        <Route path='/longrefer' component={LongRefer} />
        <Route path='/thanksforapplying' component={ThanksForApplying} />
        <Route path='/panel/:panelId/:panelExpertId' component={Panel} />
        <Route exact path='/:guid' component={SurveyRedirect} />
        <Route path='/:keyword/:guid' component={SurveyRedirect} />
        <Route path='/' component={HomeRedirect} />
      </Switch>
    );
  }
}

export default App;
