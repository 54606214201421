let host: any;
host = window.location.host || 'expertsmarketresearch.com';

if(host.includes("hub.newtonx")){
    host = 'expertsmarketresearch.com';
} else if(host.includes("hub.")){
    host = host.replace('hub.', '')
}
if(host.includes("www.")){
    host = host.replace('www.', '')
}

const dev = {
    API_BASE_PATH : 'http://localhost:8018/api/v1',
    REACT_APP_LINKEDIN_CLIENT_KEY: '7756moo2ahtfq0'
};

const devInt= {
    API_BASE_PATH : 'https://hub.' + host + '/api/v1',
    REACT_APP_LINKEDIN_CLIENT_KEY: '7756moo2ahtfq0'
};

const staging = {
    API_BASE_PATH : 'https://hub.' + host + '/api/v1',
    REACT_APP_LINKEDIN_CLIENT_KEY: '7756moo2ahtfq0'
};

const prod = {
    API_BASE_PATH : 'https://hub.' + host + '/api/v1',
    REACT_APP_LINKEDIN_CLIENT_KEY: '770p3p4dn55cb3'
}

let environment:any;

if (process.env.REACT_APP_STAGE === 'production') {
    environment = prod;
} else if (process.env.REACT_APP_STAGE === 'staging') {
    environment = staging;
} else if (process.env.REACT_APP_STAGE === 'development') {
    environment = devInt;
} else if (process.env.REACT_APP_STAGE === 'local') {
    environment = dev;
} else {
    environment = dev; 
}

export default environment;
