import React, { Component } from "react";
import { RouteComponentProps } from 'react-router';
import { Redirect } from "react-router-dom";

import { Form, Input, Button, Row, Col, Spin, Alert, Popover } from 'antd';
import { QuestionCircleTwoTone } from '@ant-design/icons';

import '../assets/styles/LongRefer.css';
import MessageBody from "./MessageBody";
import { verifySurveyExpertWithRegistration } from '../utils/apis/ExpertSurveyApi';
import {VerifySurveyExpertInfo} from "../utils/models/VerifySurveyExpertInfo";

interface ReferRouteProps {
  refId: string
}

interface LongReferStates {
  waringMessage?: string,
  waringDescription?: string,
  loading: boolean,
  requiredFieldMissing: boolean,
  professionalEmailMissing: boolean,
  emailDomainNotMatching: boolean,
  redirectToThanksForApplying: boolean,
  redirectToError: boolean,
  guid?: string
}

export default class LongRefer extends Component<RouteComponentProps<ReferRouteProps>, LongReferStates> {

  static propTypes = {};

  state: Readonly<LongReferStates> = {
    waringMessage: "",
    waringDescription: "",
    loading: false,
    requiredFieldMissing: false,
    professionalEmailMissing: false,
    emailDomainNotMatching: false,
    redirectToError: false,
    redirectToThanksForApplying: false,
    guid: ""
  }

  header = 'We need a few details from you';
  messageToDisplay = `To ensure a high quality level for our survey, please fill out the below, 
                      we'll then email you if it seems like you are a fit!`;

  emailPopoverContent = (
    <div>
      <p>Please note that only PROFESSIONAL EMAIL ADDRESS are accepted!</p>
      <p>We only work with professional email addresses that match the public website of the employer (example john.smith@nike.com for www.nike.com)</p>
      <p>We do not accept gmail, outlook, yahoo, me, hotmail email addresses</p>
    </div>
  );

  renderRedirectToError = () => {
    if (this.state.redirectToError) {
      return <Redirect to='/error' />
    }
  }

  renderRedirectToThanksForApplying = () => {
    if (this.state.redirectToThanksForApplying) {
      return <Redirect to='/thanksforapplying' />
    }
  }

  renderLoader = () => {
    if (this.state.loading) {
      return <div className="loader__container"><Spin size="large" /></div>
    }
  }

  validateRequiredFields = (values: VerifySurveyExpertInfo) => {
    if (!values.firstName || !values.firstName.trim() ||
      !values.lastName || !values.lastName.trim() ||
      !values.companyUrl || !values.companyUrl.trim() ||
      !values.email || !values.email.trim() ||
      !values.jobRole || !values.jobRole.trim() ||
      !values.country || !values.country.trim()) {
      this.setState({ requiredFieldMissing: true });
    } else {
      this.setState({ requiredFieldMissing: false });
    }
  }

  validateEmail = (companyUrl: string, email: string) => {
    if (!companyUrl || !email) {
      return;
    }
    const emailDomain = email.substring(email.indexOf("@") + 1);
    var websiteDomain = (new URL(companyUrl)).hostname.replaceAll('www.', '');

    // email domain and website domain should match
    if (emailDomain !== websiteDomain) {
      this.setState({ emailDomainNotMatching: true });
      return;
    }

    // should not use any public domain like @gmail, @outlook, @yahoo, @me, @hotmail
    if (emailDomain.endsWith('gmail.com') || emailDomain.endsWith('outlook.com') || emailDomain.endsWith('yahoo.com') ||
      emailDomain.endsWith('me.com') || emailDomain.endsWith('hotmail.com')) {
      this.setState({ professionalEmailMissing: true });
      return;
    }
    this.setState({ professionalEmailMissing: false, emailDomainNotMatching: false });
  }

  onFinish = async (values) => {
      const queries = new URLSearchParams(this.props.location.search);
      const refId = String(queries.get('refId'));

      this.validateRequiredFields(values);
      this.validateEmail(values.companyUrl, values.email);

      if (this.state.professionalEmailMissing || this.state.requiredFieldMissing || this.state.emailDomainNotMatching) {
        return;
      }

      this.setState({ loading: true });

      const requestData: VerifySurveyExpertInfo = {
        firstName: values.firstName,
        lastName: values.lastName,
        companyUrl: values.companyUrl,
        email: values.email,
        jobRole: values.jobRole,
        country: values.country,
        linkedinProfile: `https://www.linkedin.com/in/fake-referredsurvey-${values.email}`,
        refId: refId
      }
    try {
      const res = await verifySurveyExpertWithRegistration(requestData);
      this.setState({ loading: false, guid: res.guid, redirectToThanksForApplying: true });
    } catch (error) {
      this.setState({ loading: false, redirectToError: true });
    }
  }

  onClose = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    this.setState({ requiredFieldMissing: false, professionalEmailMissing: false });
  };

  renderFormValidationMessage = () => {
    let message = '';
    let description = '';
    if (this.state.requiredFieldMissing) {
      message = 'All fields are required';
      description = 'Please input all of them to finish your submission';
    } else if (this.state.professionalEmailMissing) {
      message = 'Please use a professional email';
      description = 'Without a professional email you will not be able to take the survey';
    } else if (this.state.emailDomainNotMatching) {
      message = `The email domain doesn't match with the domain of the company website`;
      description = 'We only work with professional email addresses that match with the public website of the employer (example john.smith@nike.com for www.nike.com)';
    }

    if (this.state.requiredFieldMissing || this.state.professionalEmailMissing || this.state.emailDomainNotMatching) {
      return <Row justify="center">
        <Col span={14} offset={2}>
          <Alert
            message={message}
            description={description}
            type="warning"
            showIcon
            closable
            onClose={this.onClose}
          />
        </Col>
      </Row>
    }
  }

  render() {
    return (
      <>
        {this.renderRedirectToError()}
        {this.renderRedirectToThanksForApplying()}
        <MessageBody header={this.header} subtext={this.messageToDisplay || ''} />
        <Row gutter={[0, 16]} justify="center">
          <Col span={14} offset={2}>
            <Form size="large"
              name="register"
              onFinish={this.onFinish}>
              <Form.Item name="firstName">
                <Input placeholder="Your First name" />
              </Form.Item>
              <Form.Item name="lastName">
                <Input placeholder="Your Last name" />
              </Form.Item>
              <Form.Item name="companyUrl" rules={[
                { type: 'url', message: 'Not a valid website address' }
              ]}>
                <Input placeholder="The website of your employer, i.e. https://www.nike.com" />
              </Form.Item>
              <Form.Item name="email"
                rules={[
                  { type: 'email', message: 'Not a valid Email address' }
                ]}>
                <Input
                  placeholder="Your professional email address i.e. john.smith@nike.com"
                  suffix={
                    <Popover content={this.emailPopoverContent}>
                      <QuestionCircleTwoTone />
                    </Popover>
                  }
                />
              </Form.Item>
              <Form.Item name="jobRole">
                <Input placeholder="Your Job Role" />
              </Form.Item>
              <Form.Item name="country">
                <Input placeholder="Your Country" />
              </Form.Item>
              <Form.Item className="text-center">
                <Button size="large" type="default" htmlType="submit">
                  Apply to take the Survey
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {
          this.renderFormValidationMessage()
        }
      </>
    );
  }

}




