import React from 'react';
import MessageBody from "./MessageBody";

export default () => {
  const header: string = 'Thanks for your submission!';
  const subtext: string = `Someone from our team will reach out to you within the next 48 hours if you seem like a good fit for the survey.

Please look out for an email from an @newtonx.com address with your unique survey link.

In some cases, the email can go into your spam folder. Please check it to ensure you get access to it.`
  return <MessageBody header={header} subtext={subtext} />
}