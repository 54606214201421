import React from 'react';
import MessageBody from "./MessageBody";
import LinkedIn from "linkedin-login-for-react";
import {Col, Row} from "antd";
import '../assets/styles/Refer.css';
import environment from "../environments/env";

interface LinkedinLoginProps {
  callbackLinkedIn: any
}

const LinkedinLogin: React.FunctionComponent<LinkedinLoginProps> = (props: LinkedinLoginProps) => {

  const clientId = environment.REACT_APP_LINKEDIN_CLIENT_KEY;

  const header = 'Great!';
  const message = 'We use Linkedin to make this process easier and faster for you. Please click the button below to start.';
  return (
    <div>
      <MessageBody subtext={message} header={header}/>
      <Row gutter={[0, 16]}>
        <Col span={4} offset={6}>
          <LinkedIn
            clientId={clientId}
            scope={["r_liteprofile", "r_emailaddress"]}
            callback={props.callbackLinkedIn}
            className='linkedinBtn'
            text=""
          />
        </Col>
      </Row>
    </div>
  )
}

export default LinkedinLogin;