import { SurveyRequestKwStatusMapInfo } from "../models/SurveyRequestKwStatusMapInfo";
import { VerifySurveyExpertInfo } from "../models/VerifySurveyExpertInfo";
import { RedirectInfo, ModuleType, RedirectInfoType } from "../models/RedirectInfo";
import axios from 'axios';
import environment from "../../environments/env";
import { SurveyRequestExpert } from "../models/SurveyRequestExpert";
import { SurveyExpertInfo } from "../models/SurveyExpertInfo";
import { SurveyRybbonPaymentResponseInfo } from "../models/SurveyRybbonPaymentResponseInfo";

const apiClient = axios.create({
    baseURL: environment.API_BASE_PATH,
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json'
    }
});

export const getRedirectInfoByGuid = async (guid: string, keyword?: string, extraQueries?: any) => {
    const response = await apiClient.get<RedirectInfo>('/survey/expert', {
        params: {
            ...extraQueries,
            keyword: keyword,
            guid: guid,
        }
    });
    return {
        ...response.data,
        moduleType: ModuleType[response.data.moduleType],
        type: RedirectInfoType[response.data.type]
    };
}

export const saveSurveyExpertOptInAndTCAccepted = async (guid: string, optIn: boolean, tcAccepted: boolean) => {
    const surveyExpertInfo: SurveyExpertInfo = {
        optIn: optIn,
        tcAccepted: tcAccepted
    }
    await apiClient.patch('/survey/expert', surveyExpertInfo, {
        params: {
            guid: guid
        }
    })
}

export const getOrCreateSurveyGuidForPanelExpert = async (panelId: string, panelExpertId: string) => {
    const panelExpert: SurveyRequestExpert = {
        panelId: panelId,
        panelExpertId: panelExpertId
    }
    const response = await apiClient.post<SurveyRequestExpert>('/survey/expert/panel', panelExpert);
    return response.data;
}

export const getSurveyExpertRybbonPayment = async (guid: string) => {
    const response = await apiClient.get<SurveyRybbonPaymentResponseInfo>('/survey/expert/rybbonpayment', {
        params: {
            guid: guid
        }
    });
    return response.data;
}

export const sendSurveyExpertRybbonPayment = async (guid: string, surveyExpertInfo: SurveyExpertInfo) => {
    const response = await apiClient.post<SurveyRybbonPaymentResponseInfo>('/survey/expert/rybbonpayment',
        surveyExpertInfo, {
        params: {
            guid: guid
        }
    });
    return response.data;
}

export const getSurveyExpertLinkedinDetails = async (authCode: string, redirectUri: string) => {
    console.log(authCode);
    const response = await apiClient.post<VerifySurveyExpertInfo>('/survey/expert/linkedindetails', null, { params: { authCode, redirectUri } });
    return response.data;
}

export const getSurveyKwStatusMapByRefId = async (refId: string) => {
    const response = await apiClient.get<SurveyRequestKwStatusMapInfo>('/survey/expert/verification/map', {
        params: {
            refId: refId
        }
    });
    return response.data;
}

export const verifySurveyExpert = async (linkedinProfile: string, surveyExpertRegistrationId?: number, refId?: string, email?: string) => {
    const verifySurveyExpert: VerifySurveyExpertInfo = {
        linkedinProfile: linkedinProfile,
        surveyExpertRegistrationId: surveyExpertRegistrationId,
        refId: refId,
        email: email
    }
    const response = await apiClient.post<VerifySurveyExpertInfo>('/survey/expert/verify', verifySurveyExpert, {
        transformResponse: (response) => {
            return response;
        }
    });
    return response.data;
}

export const verifySurveyExpertWithRegistration = async (payload: VerifySurveyExpertInfo) => {
    const response = await apiClient.post<VerifySurveyExpertInfo>('/survey/expert/verify/registration', payload, {
        transformResponse: (response) => {
            return response;
        }
    });
    return response.data;
}
