import React from "react";
import {Col, Row, Typography} from "antd";
import ReactMarkdown from "react-markdown";

interface MessageBodyProps {
  header?: string,
  subtext: string
}

const { Title, Paragraph } = Typography;


const MessageBody:React.FunctionComponent<MessageBodyProps> = (props:MessageBodyProps) => {
  return (
    <div>
      {
        props.header && <Row gutter={[0, 16]} style={{ marginTop: 32 }}>
            <Col span={14} offset={6}><Title level={3} style={{ fontSize: 32 }}>{props.header}</Title></Col>
        </Row>
      }
      <Row gutter={[0, 16]}>
        <Col span={14} offset={6}><Paragraph>
          <ReactMarkdown source={props.subtext} skipHtml={true} linkTarget='_blank' />
        </Paragraph></Col>
      </Row>
    </div>
  );
};

export default MessageBody;