import React, { Component } from 'react';
import {Button, Typography, Row, Col, Space, Alert, message, Radio} from 'antd';
import {saveSurveyExpertOptInAndTCAccepted} from '../utils/apis/ExpertSurveyApi';
import { Redirect } from 'react-router-dom';
import {redirectToLinkWithExtraQueries} from "../utils/utilities";
import MessageBody from "./MessageBody";
import ReactMarkdown from "react-markdown";
import LinkToPopupRenderer from "./LinkToPopupRenderer";

interface OnboardingRouteProps {
    guid: string,
    redirectLink: string,
    header?: string,
    subtext: string,
    termsHeader?: string,
    showTerms?: boolean,
    optInMessage?: string,
    endMessage?: string,
    curQueries: URLSearchParams
}

interface OnboardingStates {
    redirectToError: boolean,
    tcAccepted?: boolean,
    optIn?: boolean,
    NotAcceptTermsClicked: boolean
}

const { Text } = Typography;

export default class Onboarding extends Component<OnboardingRouteProps, OnboardingStates> {
    
    state: OnboardingStates = {
        redirectToError: false,
        NotAcceptTermsClicked: false,
        tcAccepted: this.props.showTerms ? undefined : false
    }

    handleContinueClick = async () => {
        if (this.state.tcAccepted === undefined) {
            message.warning("Press Yes to accept our terms and conditions. Press no to refuse.")
            return;
        }
        if (this.state.optIn === undefined) {
            message.warning("Press Yes to opt in for future opportunities. Press no to refuse.")
            return;
        }
        try {
            await saveSurveyExpertOptInAndTCAccepted(this.props.guid, this.state.optIn, this.state.tcAccepted);
        } catch (error) {
            this.setState({redirectToError: true});
        }
        redirectToLinkWithExtraQueries(this.props.redirectLink, this.props.curQueries);
    }

    handleWarningClose = () => {
        this.setState({ NotAcceptTermsClicked: false });
    }

    selectOptIn = (event: any) => {
        this.setState({ optIn: event.target.value });
    }

    selectTCAccepted = (event: any) => {
        const tcAccepted = event.target.value;
        if (tcAccepted == "true") {
            this.setState({ tcAccepted: true, NotAcceptTermsClicked: false });
        } else {
            this.setState({ tcAccepted: false, NotAcceptTermsClicked: true });
        }
    }

    renderRedirectToError = () => {
        if (this.state.redirectToError) {
            return <Redirect to='/error' />
        }
    }

    shouldDisableContinue = () => {
        if (this.state.optIn === undefined) {
            return true;
        }
        if (this.props.showTerms && !this.state.tcAccepted) {
            return true
        }
        return false;
    }
    
    render() {
        
        return (
            <div>
                {this.renderRedirectToError()}
                <MessageBody subtext={this.props.subtext} header={this.props.header} />
                {
                    this.props.showTerms &&
                    <Row gutter={[0, 16]} align="middle">
                        <Col span={8} offset={6}><Text>
                            <ReactMarkdown source={this.props.termsHeader} escapeHtml={true}
                                           renderers={{link: (props) => LinkToPopupRenderer(props)}} />
                        </Text></Col>
                        <Col span={8} offset={1}>
                            <Space>
                                <Radio.Group onChange={(event) => this.selectTCAccepted(event)}
                                             value={String(this.state.tcAccepted)}>
                                    <Radio.Button value="true">Yes</Radio.Button>
                                    <Radio.Button value="false">No</Radio.Button>
                                </Radio.Group>
                            </Space>
                        </Col>
                    </Row>
                }
                {
                    this.props.showTerms && this.state.NotAcceptTermsClicked && <Row gutter={[0, 16]}><Col offset={6}>
                        <Alert message="Selecting no to terms and conditions means you will not be able to take the survey."
                               type="warning" showIcon closable onClose={this.handleWarningClose} />
                    </Col></Row>
                }
                {
                    !this.state.NotAcceptTermsClicked && <Row gutter={[0, 16]} align="middle">
                        <Col span={8} offset={6}><Text>
                            <ReactMarkdown source={this.props.optInMessage} escapeHtml={true} />
                        </Text></Col>
                        <Col span={8} offset={1}>
                            <Space>
                                <Radio.Group onChange={(event) => this.selectOptIn(event)} value={String(this.state.optIn)}>
                                    <Radio.Button value="true">Yes</Radio.Button>
                                    <Radio.Button value="false">No</Radio.Button>
                                </Radio.Group>
                            </Space>
                        </Col>
                    </Row>
                }
                {
                    this.props.endMessage &&
                    <Row gutter={[0, 16]}>
                        <Col span={14} offset={6}><Text>
                            <ReactMarkdown source={this.props.endMessage} escapeHtml={true} />
                        </Text></Col>
                    </Row>
                }
                <Row>
                    <Col span={2} offset={15}>
                        <Button size="large" type="primary" onClick={this.handleContinueClick}
                                disabled={this.shouldDisableContinue()}>
                            Continue
                        </Button>
                    </Col>
                </Row>
            </div>
        )
        
    }
}