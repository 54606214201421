import React from 'react';
import '../assets/styles/Message.scss';
import { Button, Typography } from "antd";
import ReactMarkdown from "react-markdown";

interface MessageProps {
    message: string,
    handleButtonClick: Function
}

const { Paragraph } = Typography;

const Message:React.FunctionComponent<MessageProps> = (props:MessageProps) => {
    return (
        <div className='message__container'>
            <Paragraph type="secondary"><ReactMarkdown source={props.message} escapeHtml={true} /></Paragraph>
            <Button onClick={() => props.handleButtonClick()} >Go to NewtonX</Button>
        </div>
    );
};

export default Message;