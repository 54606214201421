import React, { Component } from 'react';
import queryString from 'query-string';
import Message from './Message';
import { RouteComponentProps } from 'react-router';
import Loader from './Loader';
import Header from './Header';
import Footer from './Footer';

import '../assets/styles/Redirect.scss';
import '../assets/styles/Loader.scss';
import { RedirectInfo, RedirectInfoType, ModuleType } from '../utils/models/RedirectInfo';
import Onboarding from './Onboarding';
import { getRedirectInfoByGuid } from '../utils/apis/ExpertSurveyApi';
import { Redirect } from 'react-router-dom';
import { redirectToLinkWithExtraQueries } from "../utils/utilities";
import MessageBody from "./MessageBody";
import Payment from './Payment';

interface SurveyRedirectRouteProps {
    keyword?:string,
    guid: string
}

interface SurveyRedirectStates {
    loading: boolean,
    redirectInfo: RedirectInfo,
    redirectToError: boolean
}

export default class SurveyRedirect extends Component<RouteComponentProps<SurveyRedirectRouteProps>, SurveyRedirectStates> {
    state: Readonly<SurveyRedirectStates> = {
        redirectInfo: {
            type: RedirectInfoType.LINK,
            message: '',
            moduleType: ModuleType.CUSTOM,
            link: ''
        },
        loading: true,
        redirectToError: false
    }

    async componentDidMount() {
        const {
            keyword,
            guid,
        } = this.props.match.params;
        const queries = queryString.parse(this.props.location.search);
        try {
            const redirectInfo: RedirectInfo = await getRedirectInfoByGuid(guid, keyword, queries);
            this.setState({ 
                redirectInfo,
                loading: false,
             });
        } catch (error) {
            this.setState({ redirectToError: true });
        }
    }

    handleGoToNewtonXClick = () => {
        window.location.replace('https://newtonx.com');
    }

    renderRedirectToError = () => {
        if (this.state.redirectToError) {
            return <Redirect to='/error' />
        }
    }

    render() {
        const isPayment: boolean = !!this.state.redirectInfo.paymentAmount && this.state.redirectInfo.paymentAmount !== 0;
        const isMessage: boolean = this.state.redirectInfo.type === RedirectInfoType.MESSAGE && !isPayment;
        const isLoading: boolean = this.state.loading;
        const isOnboarding: boolean = this.state.redirectInfo.moduleType === ModuleType.ONBOARDING;
        const shouldRedirect: boolean = !isPayment && !isMessage && !isOnboarding && !isLoading;
        const isOldSurveyWithNoHeader: boolean = this.state.redirectInfo.header == null;

        if (shouldRedirect && this.state.redirectInfo.type === RedirectInfoType.LINK && this.state.redirectInfo.link) {
            redirectToLinkWithExtraQueries(this.state.redirectInfo.link, new URLSearchParams(this.props.location.search));
        }
        return (
            <div className='redirect__container'>
                {this.renderRedirectToError()}
                {
                    isOnboarding && <Onboarding 
                        guid={this.props.match.params.guid} 
                        redirectLink={this.state.redirectInfo.link === undefined ? '' : this.state.redirectInfo.link}
                        header={this.state.redirectInfo.header}
                        subtext={this.state.redirectInfo.message === undefined ? '' : this.state.redirectInfo.message}
                        termsHeader={this.state.redirectInfo.termsHeader}
                        showTerms={this.state.redirectInfo.showTerms}
                        optInMessage={this.state.redirectInfo.optInMessage}
                        endMessage={this.state.redirectInfo.endMessage}
                        curQueries={new URLSearchParams(this.props.location.search)} />
                }
                {
                    isPayment && <Payment
                        guid={this.props.match.params.guid}
                        redirectLink={this.state.redirectInfo.link === undefined ? '' : this.state.redirectInfo.link}
                        header={this.state.redirectInfo.header}
                        subtext={this.state.redirectInfo.message === undefined ? '' : this.state.redirectInfo.message} 
                        curQueries={new URLSearchParams(this.props.location.search)} />
                } 
                {
                    isMessage && !isOldSurveyWithNoHeader && <MessageBody
                      subtext={this.state.redirectInfo.message === undefined ? '' : this.state.redirectInfo.message}
                                              header={this.state.redirectInfo.header} />
                }
                {
                    isMessage && isOldSurveyWithNoHeader && <Header handleLogoClick={this.handleGoToNewtonXClick} />
                }
                {
                    isMessage && isOldSurveyWithNoHeader && <Message handleButtonClick={this.handleGoToNewtonXClick}
                                                                     message={this.state.redirectInfo.message === undefined ? '' : this.state.redirectInfo.message} />
                }
                {
                    isMessage && isOldSurveyWithNoHeader && <Footer handleLogoClick={this.handleGoToNewtonXClick} />
                }

                {
                    isLoading && (
                        <div className='loader__container'>
                            <Loader />
                        </div>
                    )
                }
            </div>
        )
    }
}