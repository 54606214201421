export const redirectToLinkWithExtraQueries = (redirectToLink: string, curQueries: URLSearchParams) => {
    let link = new URL(redirectToLink);
    let linkParams = link.searchParams;
    const passAlongQueryParams: string[] = ['lang', 'code', 'CNT'];
    passAlongQueryParams.forEach(queryParam => {
        if (curQueries && curQueries.has(queryParam)) {
            linkParams.append(queryParam, String(curQueries.get(queryParam)));
        }
    });
    window.location.replace(link.href);
};