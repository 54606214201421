export enum RedirectInfoType {
    LINK = 'LINK',
    MESSAGE = 'MESSAGE'
}

export enum ModuleType {
    ONBOARDING = 'ONBOARDING',
    VERIFICATION = 'VERIFICATION',
    CUSTOM = 'CUSTOM',
    PAYMENT_FINISHED = 'PAYMENT_FINISHED',
    COMPLETED = 'COMPLETED',
    POST_ONBOARDING = 'POST_ONBOARDING',
    SCREENED = 'SCREENED',
    FRAUD = 'FRAUD',
    QUOTA = 'QUOTA'
}

export interface RedirectInfo {
    type: RedirectInfoType,
    message?: string,
    header?: string,
    link?: string,
    termsHeader?: string,
    showTerms?: boolean,
    optInMessage?: string,
    endMessage?: string,
    paymentAmount?: number,
    moduleType: ModuleType
}