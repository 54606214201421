import React, {Component} from "react";
import {Button, Row, Col, Space, Spin} from 'antd';
import {getSurveyExpertLinkedinDetails} from '../utils/apis/ExpertSurveyApi';
import {getSurveyKwStatusMapByRefId} from '../utils/apis/ExpertSurveyApi';
import {Redirect} from "react-router-dom";
import MessageBody from "./MessageBody";
import {RouteComponentProps} from 'react-router';
import {SurveyRequestKwStatusMapInfo} from '../utils/models/SurveyRequestKwStatusMapInfo';
import LinkedinLogin from "./LinkedinLogin";
import LinkedinVerification from "./LinkedinVerification";
import '../assets/styles/Redirect.scss';

interface ReferRouteProps {
  refId: string
}

interface ReferStates {
  redirectToError: boolean,
  redirectToSuccess: boolean,
  redirectToNoInterest: boolean,
  redirectToNoLinkedIn: boolean,
  header?: string,
  messageToDisplay?: string,
  deactivateRequest?: boolean,
  loading: boolean,
  showLinkedinLogin: boolean,
  surveyRegistrationId?: number,
  requireManualVerification?: boolean,
  email?: string
}

export default class Refer extends Component<RouteComponentProps<ReferRouteProps>, ReferStates> {

  static propTypes = {};
  state: Readonly<ReferStates> = {
    header: "",
    messageToDisplay: "",
    deactivateRequest: false,
    redirectToError: false,
    redirectToSuccess: false,
    redirectToNoInterest: false,
    loading: true,
    showLinkedinLogin: false,
    surveyRegistrationId: 0,
    redirectToNoLinkedIn: false,
    requireManualVerification: true
  }

  async componentDidMount() {
    const queries = new URLSearchParams(this.props.location.search);
    const refId: string = String(queries.get('refId'));
    const code = queries.get('code');
    try {
      const surveyRequestKwStatusMapInfo: SurveyRequestKwStatusMapInfo = await getSurveyKwStatusMapByRefId(refId);
      if (!code) {
        this.setState({
          header: surveyRequestKwStatusMapInfo.header,
          messageToDisplay: surveyRequestKwStatusMapInfo.messageToDisplay,
          deactivateRequest: surveyRequestKwStatusMapInfo.deactivateRequest,
          loading: false,
        });
      } else {
        this.setState({
          requireManualVerification: surveyRequestKwStatusMapInfo.requireManualVerification,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({redirectToError: true});
    }
  }

  handleYesClick = () => {
    this.setState({showLinkedinLogin: true});
  }

  handleNoClick = () => {
    this.setState({ redirectToNoInterest: true });
  }

  handleNoLinkedInClick = () => {
    this.setState({ redirectToNoLinkedIn: true });
  }

  renderErrorMessage = () => {
    if (this.state.redirectToError) {
      return <Redirect to='/error'/>;
    } else if (this.state.redirectToNoInterest) {
      return <Redirect to='/thanks-notamatch' />;
    }
  }

  renderLoader = () => {
    if (this.state.loading) {
      return <div className="loader__container"><Spin size="large" /></div>
    }
  }

  renderLongRefer = (refId: string) => {
    if (this.state.redirectToNoLinkedIn) {
      return <Redirect to={`/longrefer?refId=${refId}`}/>;  
    }
  }

  /*
    ** @code = authorization code from linkedin api
    ** @redirectUri = redirect uri from linkedin api
    ** @error = error message sign in failed
    */
  callbackLinkedIn = async (error: any, code: any, redirectUri: any) => {
    this.setState({loading: true});

    if (error) {
      this.setState({redirectToError: true});
    }

    //call backend api to take token
    try {
      const res = await getSurveyExpertLinkedinDetails(code, redirectUri);
      this.setState({...this.state, redirectToSuccess: true, surveyRegistrationId: res.surveyExpertRegistrationId, loading: false, email:res.email });
    } catch (error) {
      this.setState({redirectToSuccess: false, redirectToError: true, loading: false});
    }
  };

  render() {
    const queries = new URLSearchParams(this.props.location.search);
    const code = queries.get('code');
    const refId = String(queries.get('refId'));
    const showLinkedLogin = !this.state.redirectToSuccess && !this.state.redirectToError;
    const showLinkedinButton = this.state.showLinkedinLogin || code !== null;
    const showLongRefer = this.state.redirectToNoLinkedIn;

    return (
      <div>
        {this.renderLoader()}
        {this.renderErrorMessage()}
        {
          (this.state.redirectToSuccess || code) &&
          <LinkedinVerification refId={refId} surveyRegistrationId={this.state.surveyRegistrationId} requireManualVerification={this.state.requireManualVerification} email={this.state.email} />
        }
        {
          showLinkedLogin &&
          <div style={{ display: (!code ? 'block' : 'none') }}><MessageBody subtext={this.state.messageToDisplay === undefined ? '' :
            this.state.messageToDisplay} header={this.state.header}/>
           { !this.state.deactivateRequest &&  
              <Row gutter={[0, 32]}>
                  <Col span={3} offset={6}>
                      <Space size="middle">
                          <Button size="large" type={this.state.showLinkedinLogin ? "primary" : "default"}
                                  onClick={() => this.handleYesClick()}>Yes</Button>
                          <Button size="large" type="default" onClick={() => this.handleNoClick()}>No</Button>
                      </Space>
                  </Col>
              </Row>
            }
            {
              showLinkedinButton && <LinkedinLogin callbackLinkedIn={this.callbackLinkedIn}/>
            }
            {
              showLinkedinButton && 
              <Row gutter={[0, 32]}>
                  <Col span={3} offset={6}>
                      <Space size="middle">
                      <Button size="large" type="default" onClick={() => this.handleNoLinkedInClick()}>I don't have LinkedIn</Button>
                      </Space>
                  </Col>
              </Row>              
            }
            {
              showLongRefer && this.renderLongRefer(refId)
            }
          </div>
        }

      </div>
    );
  }

}




