import React from 'react';
import MessageBody from "./MessageBody";

export default () => {
    const header: string = 'Something went wrong';
    const subtext: string = `Looks like something unexpected happened.

Please close this window and return to the site using the link provided in the email.

If you have any further issues, reply to the email and we’ll help you out.`
    return <MessageBody header={header} subtext={subtext} />
}