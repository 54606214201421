import React from 'react';
import MessageBody from "./MessageBody";

export default () => {
    const header: string = 'Payment is being processed';
    const subtext: string = ` We need to do some additional verification checks before payment will be sent. You should 
    receive an email in 1-2 weeks.

If there are any problems, please email us! We’re here to help at payments@newtonx.com.`
    return <MessageBody header={header} subtext={subtext} />
}