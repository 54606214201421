import React from 'react';
import logo from '../assets/images/logo.png';
import '../assets/styles/Header.scss';

import '../assets/styles/Header.scss';

interface HeaderProps {
    handleLogoClick: Function
}

export default ({ handleLogoClick }: HeaderProps) => {
    return (
        <div className='header__container'>
            <div></div>
            <img className='header__logo' src={logo} alt='newton-x-logo' onClick={() => handleLogoClick()} />
        </div>
    )
}