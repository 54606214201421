import React from 'react';
import logo from '../assets/images/white_logo.png';
import '../assets/styles/Footer.scss';

interface FooterProps {
    handleLogoClick: Function
}

export default ({ handleLogoClick }: FooterProps) => {
    return (
        <div className='footer-container'>
        <div className='footer-content'>
          <div className='footer-logo'>
            <img src={logo} alt='' onClick={() => handleLogoClick()} />
          </div>
          <div className='footer-info'>
            <div className='footer-info-box'>
                <span className='footer-info-box-header'><a href='https://newtonx.com/privacy' rel='noopener noreferrer' className='nav-link'>Privacy Policy</a></span>
            </div>
            <div className='footer-info-box'>
                <span className='footer-info-box-header'><a href='https://newtonx.com/security' rel='noopener noreferrer' className='nav-link'>Data Security Policy</a></span>
            </div>
          </div>
        </div>
      </div>
    )
}